import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faAngleDown,
  faCalendarEdit,
  faCalendarWeek,
  faCaretLeft,
  faCaretRight,
  faCheck,
  faCheckCircle as faCheckCircleLight,
  faCircle,
  faCircleNotch,
  faClipboardList,
  faClipboardListCheck,
  faClock,
  faCogs,
  faDoorOpen,
  faEllipsisH,
  faExchange,
  faFilePdf,
  faFragile,
  faHome,
  faListUl,
  faKey,
  faLayerGroup,
  faPlus,
  faPowerOff,
  faRunning,
  faTimes,
  faTrash,
  faUsers,
} from '@fortawesome/pro-light-svg-icons'

import {
  faCheckCircle as faCheckCircleSolid,
  faUnlock,
} from '@fortawesome/pro-solid-svg-icons'

config.autoAddCss = false

library.add(faAngleDown)
library.add(faCalendarEdit)
library.add(faCalendarWeek)
library.add(faCaretLeft)
library.add(faCaretRight)
library.add(faCheck)
library.add(faCheckCircleLight)
library.add(faCheckCircleSolid)
library.add(faCircle)
library.add(faCircleNotch)
library.add(faClipboardList)
library.add(faClipboardListCheck)
library.add(faClock)
library.add(faCogs)
library.add(faDoorOpen)
library.add(faEllipsisH)
library.add(faExchange)
library.add(faFilePdf)
library.add(faFragile)
library.add(faHome)
library.add(faListUl)
library.add(faKey)
library.add(faLayerGroup)
library.add(faPlus)
library.add(faPowerOff)
library.add(faRunning)
library.add(faTimes)
library.add(faTrash)
library.add(faUnlock)
library.add(faUsers)

Vue.component('Icon', FontAwesomeIcon)
