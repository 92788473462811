<template>
  <aside class="sidebar">
    <div class="sidebar-container">
      <change-password-modal
        v-if="isChangePasswordOpen"
        ref="changePasswordModal"
        @close="isChangePasswordOpen = false"
      />
      <edit-big-date-range-password-modal
        v-if="isEditBigDateRangePasswordOpen"
        ref="editBigDateRangePassword"
        @close="isEditBigDateRangePasswordOpen = false" />
      <switch-user-modal
        ref="switchUserModal" />
      <logo white hide-text />
      <nav class="nav">
        <nuxt-link :class="{ 'nuxt-link-active': $route.name === 'index' }"
                   to="/"
                   exact
                   title="Zeitaufwände"
                   class="nav-link"
        >
          <icon :icon="['fal', 'clock']" fixed-width />
        </nuxt-link>
        <nuxt-link :class="{ 'nuxt-link-active': $route.name === 'week-view' }"
                   to="/week-view"
                   exact
                   title="Wochenübersicht"
                   class="nav-link"
        >
          <icon :icon="['fal', 'calendar-week']" fixed-width />
        </nuxt-link>
        <nuxt-link :class="{ 'nuxt-link-active': $route.name === 'todos' }"
                   to="/todos"
                   exact
                   title="ToDos"
                   class="nav-link"
        >
          <icon :icon="['fal', 'clipboard-list-check']" fixed-width />
        </nuxt-link>
        <nuxt-link :class="{ 'nuxt-link-active': $route.name === 'defects' }"
                   to="/defects"
                   exact
                   title="Mängelliste"
                   class="nav-link"
        >
          <icon :icon="['fal', 'fragile']" fixed-width />
        </nuxt-link>
        <b-dropdown :class="{ 'nuxt-link-active': isAdminRoute }"
                    no-caret
                    variant="link"
                    title="Einstellungen"
                    class="nav-link"
        >
          <template #button-content>
            <icon :icon="['fal', 'cogs']" fixed-width />
          </template>
          <b-dropdown-item v-if="$store.getters['auth/isGranted']('ROLE_ADMIN')"
                           to="/admin/categories"
                           active-class="active">
            <icon :icon="['fal', 'layer-group']" class="mr-1" />
            Kategorien
          </b-dropdown-item>
          <b-dropdown-item v-if="$store.getters['auth/isGranted']('ROLE_ADMIN')"
                           to="/admin/default-weekly-schedule"
                           active-class="active"
          >
            <icon :icon="['fal', 'calendar-week']" class="mr-1" />
            Standardwochenplan
          </b-dropdown-item>
          <b-dropdown-item active-class="active"
                           @click.prevent.stop="isChangePasswordOpen = true">
            <icon :icon="['fal', 'key']" class="mr-1" />
            Eigenes Passwort ändern
          </b-dropdown-item>
          <b-dropdown-divider v-if="$store.getters['auth/isGranted']('ROLE_ADMIN')" />
          <b-dropdown-item v-if="$store.getters['auth/isGranted']('ROLE_ADMIN')"
                           to="/admin/users"
                           active-class="active">
            <icon :icon="['fal', 'users']" class="mr-1" />
            Benutzer
          </b-dropdown-item>
          <b-dropdown-item v-if="$store.getters['auth/isGranted']('ROLE_ADMIN') && !isUserSwitchActive"
                           active-class="active"
                           @click.prevent.stop="$refs.switchUserModal.show()">
            <icon :icon="['fal', 'exchange']" class="mr-1" />
            Mitarbeiter wechseln
          </b-dropdown-item>
          <b-dropdown-item v-if="$store.getters['auth/isGranted']('ROLE_ADMIN')"
                           active-class="active"
                           @click.prevent.stop="isEditBigDateRangePasswordOpen = true">
            <icon :icon="['fal', 'key']" class="mr-1" />
            Passwort für Arbeitszeiterfassung ändern
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item v-if="isUserSwitchActive"
                           active-class="active"
                           to="/"
                           @click.stop.prevent.native="$refs.switchUserModal.undoSwitch()">
            <icon :icon="['fal', 'power-off']" class="mr-1" />
            {{ currentUserObject !== null ? `${currentUserObject.abbreviation} (${currentUserObject.firstName} ${currentUserObject.lastName}) verlassen` : 'verlassen' }}
          </b-dropdown-item>
          <b-dropdown-item v-else
                           active-class="active"
                           to="/login"
                           @click.prevent.native="onLogout">
            <icon :icon="['fal', 'power-off']" class="mr-1" />
            Abmelden
          </b-dropdown-item>
        </b-dropdown>
      </nav>
    </div>
  </aside>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import ChangePasswordModal from "~/components/modals/ChangePasswordModal";
import EditBigDateRangePasswordModal from "~/components/modals/EditBigDateRangePasswordModal";
import Logo from '~/components/Logo'
import SwitchUserModal from "~/components/modals/SwitchUserModal";

import UserQuery from "~/graphql/query/User.gql";

export default {
  name: 'Sidebar',

  components: {
    ChangePasswordModal,
    EditBigDateRangePasswordModal,
    Logo,
    SwitchUserModal,
  },

  data() {
    return {
      currentUserObject: null,
      isChangePasswordOpen: false,
      isEditBigDateRangePasswordOpen: false,
    }
  },

  computed: {
    ...mapGetters(['currentUser']),

    isAdminRoute() {
      return this.$route.path.includes('/admin')
    },

    isUserSwitchActive() {
      return localStorage.getItem('user_switch') !== null
    },
  },

  mounted() {
    this.$apollo.query({
      query: UserQuery
    }).then((data) => {
      this.currentUserObject = data.data.user
    })
  },

  methods: {
    ...mapMutations({
      reset: 'auth/reset'
    }),

    onLogout() {
      this.reset()
      localStorage.clear()
      this.$router.push('/login')
    }
  }
}
</script>
