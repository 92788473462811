import jwtDecode from 'jwt-decode'

export const state = () => ({
  accessToken: null,
  refreshToken: null,
  expires: null,
  user: null,
  roles: []
})

export const mutations = {
  login(state, data) {
    if (data) {
      const decoded = jwtDecode(data.token)
      state.user = decoded.username
      state.roles = decoded.roles
      state.expires = decoded.exp
      state.accessToken = data.token
      state.refreshToken = data.refreshToken ?? data.refresh_token
      localStorage.setItem('auth_data', JSON.stringify(data))
    } else {
      localStorage.setItem('auth_data', '{}')
    }
  },

  reset(state) {
    localStorage.removeItem('auth_data')
    state.accessToken = null
    state.refreshToken = null
    state.expires = null
    state.user = null
    state.roles = []
  }
}

export const actions = {
  init({ commit }) {
    // Check if the ID exists
    if (localStorage.getItem('auth_data')) {
      const jsonData = JSON.parse(localStorage.getItem('auth_data'))
      commit('login', jsonData)
    }
  }
}

export const getters = {
  isLoggedIn(state) {
    return state.accessToken !== null
  },

  isGranted: (state) => (role) => {
    return state.roles.includes(role)
  }
}
