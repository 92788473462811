<template>
  <div :class="{ '-active': isVisible }" class="loading">
    <b-spinner style="width: 3rem; height: 3rem" variant="primary" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Loading',
  computed: {
    ...mapState({
      isVisible: (state) => state.loadingCount > 0
    })
  }
}
</script>
