export default (
  { graphQLErrors, networkError, operation, forward },
  nuxtContext
) => {
  if (graphQLErrors && graphQLErrors.length) {
    graphQLErrors.forEach((error) => {
      nuxtContext.$sentry.captureException(error)
    })
  }
  if (networkError) {
    if (
      networkError &&
      networkError.response &&
      networkError.response.status === 401
    ) {
      return
    }

    const extra = {
      response: null
    }

    if (networkError && 'result' in networkError) {
      extra.response = JSON.stringify(networkError.result)
    }

    nuxtContext.$sentry.captureException(networkError, { extra })
  }
}
