<template>
  <b-modal
    ref="modal"
    centered
    title="Passwort ändern"
    ok-title="Speichern"
    cancel-title="Abbrechen"
    no-close-on-esc
    no-close-on-backdrop
    no-enforce-focus
    :ok-disabled="!isFormValid"
    @ok="onSubmit"
    @hidden="onHidden"
  >
    <b-form @submit="onSubmit">
      <b-form-group label="Aktuelles Passwort" label-class="required" for="current-password">
        <b-form-input
          id="current-password"
          v-model="currentPassword"
          type="password"
          required
          autocomplete="current-password"
          autofocus
        />
      </b-form-group>
      <b-form-group
        label="Neues Passwort"
        for="new-password"
        :state="plainPassword.length === 0 ? null : isPasswordSecure"
        invalid-feedback="Das Passwort ist nicht sicher genug"
      >
        <password-strength-meter
          v-model="plainPassword"
          default-class="form-control"
          :toggle="true"
          :badge="false"
          @score="onScore"
        />
      </b-form-group>
      <b-form-group
        label="Neues Passwort wiederholen"
        label-class="required"
        for="new-password-repeat"
        :state="
          plainPasswordRepeat.length === 0 ? null : isPasswordRepeatCorrect
        "
        invalid-feedback="Die Passwörter müssen gleich sein"
      >
        <b-form-input
          id="new-password-repeat"
          v-model="plainPasswordRepeat"
          type="password"
          required
          autocomplete="new-password"
        />
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import PasswordStrengthMeter from '../PasswordStrengthMeter'
import ChangePasswordMutation from '~/graphql/mutation/ChangePasswordMutation.gql'

export default {
  name: 'ChangePasswordModal',
  components: { PasswordStrengthMeter },
  data() {
    return {
      currentPassword: '',
      plainPassword: '',
      plainPasswordRepeat: '',
      passwordScore: 0
    }
  },
  computed: {
    isPasswordSecure() {
      return this.passwordScore >= 2
    },
    isPasswordRepeatCorrect() {
      return this.plainPassword === this.plainPasswordRepeat
    },
    isFormValid() {
      return this.isPasswordRepeatCorrect && this.isPasswordSecure
    }
  },
  mounted() {
    this.$refs.modal.show()
  },
  methods: {
    onHidden() {
      this.reset()
      this.$emit('close')
    },
    reset() {
      this.currentPassword = ''
      this.plainPassword = ''
      this.plainPasswordRepeat = ''
    },
    onSubmit(e) {
      e.preventDefault()
      this.$apollo
        .mutate({
          mutation: ChangePasswordMutation,
          variables: {
            oldPassword: this.currentPassword,
            newPassword: this.plainPassword
          }
        })
        .then(() => {
          this.$bvToast.toast('Password wurde erfolgreich geändert', {
            variant: 'success',
            solid: true,
            noCloseButton: true,
            toaster: 'b-toaster-top-center'
          })

          this.$refs.modal.hide()
        })
        .catch(() => {
          this.$bvToast.toast('Password konnte nicht geändert werden', {
            variant: 'danger',
            solid: true,
            noCloseButton: true,
            toaster: 'b-toaster-top-center'
          })
        })
    },
    show() {
      this.$refs.modal.show()
    },
    onScore(score) {
      this.passwordScore = score
    }
  }
}
</script>

<style scoped></style>
