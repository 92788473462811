<template>
  <b-modal
    ref="modal"
    title="Passwort für Arbeitszeiterfassung ändern"
    lazy
    @ok.prevent="onSubmit"
    @hidden="onHidden"
  >
    <template #modal-footer="{ ok, cancel }">
      <div class="w-100">
        <div class="float-right">
          <b-button variant="link" @click="cancel()">
            Schließen
          </b-button>
          <b-button :disabled="isLoading" variant="primary" @click="ok()">
            <b-spinner v-if="isLoading" small />
            Speichern
          </b-button>
        </div>
      </div>
    </template>

    <form autocomplete="off" @submit.stop.prevent="onSubmit">
      <b-form-group label="Passwort" label-for="password" label-class="required">
        <b-form-input id="password" v-model="password" autofocus required />
        <form-validation-error :property="$v.password" label="Passwort" />
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import BigDateRangePasswordQuery from '~/graphql/query/BigDateRangePassword.gql'
import UpdateBigDateRangePasswordMutation from '~/graphql/mutation/UpdateBigDateRangePasswordMutation.gql'
import FormValidationError from '~/components/FormValidationError'

export default {
  name: 'EditBigDateRangePasswordModal',

  components: { FormValidationError },

  data() {
    return {
      isLoading: false,
      password: '',
    }
  },

  mounted() {
    this.$apollo.query({
      query: BigDateRangePasswordQuery,
      fetchPolicy: 'no-cache',
    }).then((data) => {
      this.password = data.data.bigDateRangePassword
    })

    this.$refs.modal.show()
  },

  methods: {
    onHidden() {
      this.$emit('close')
    },

    onSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.isLoading = true

      return this.updateBigDateRangePasswordMutation().then(() => {
        this.isLoading = false
        this.$refs.modal.hide()
      })
    },

    updateBigDateRangePasswordMutation() {
      return this.$apollo.mutate({
        mutation: UpdateBigDateRangePasswordMutation,
        variables: {
          password: this.password,
        },
      })
    }
  },

  validations: {
    password: {
      required
    }
  }
}
</script>
