import { setContext } from 'apollo-link-context'
import { ApolloLink } from 'apollo-link'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { DedupLink } from 'apollo-link-dedup'
import { createUploadLink } from 'apollo-upload-client'

export default function({ store, env, redirect }) {
  store.dispatch('auth/init')

  const uploadLink = createUploadLink({
    uri: env.GRAPHQL_API_URL,
  })

  const authLink = setContext((_, { headers }) => {
    const token = store.state.auth.accessToken

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  const tokenRefreshLink = new TokenRefreshLink({
    accessTokenField: 'token',

    isTokenValidOrUndefined: () => {
      if (typeof store.state.auth.accessToken !== 'string') {
        return true
      }

      const now = Math.floor(Date.now() / 1000)

      return store.state.auth.expires > now
    },

    fetchAccessToken: () => {
      return fetch(`${env.API_URL}api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refresh_token: store.state.auth.refreshToken
        }),
      })
        .then(response => response.json())
        .then((data) => {
          data.__typename = 'AccessToken'
          return data
        })
    },

    handleResponse: () => async (response) => {
      store.commit('auth/login', await response)
      return {
        token: response.token
      }
    },

    handleFetch: () => {
      // Die Methode muss leider da sein, sonst meckert der Browser, weil handleFetch keine Methode ist...
    },

    handleError: () => {
      store.commit('auth/reset')
      redirect('/login')
    }
  })

  const dedupLink = new DedupLink()

  const link = ApolloLink.from([
    dedupLink,
    tokenRefreshLink,
    authLink,
    uploadLink
  ])

  return {
    wsEndpoint: null,
    defaultHttpLink: false,
    link
  }
}
