import { format, startOfWeek, endOfWeek, parse } from 'date-fns'

export const state = () => ({
  loadingCount: 0,

  selectedCategories: [],

  startDateString: format(
    startOfWeek(new Date(), { weekStartsOn: 1 }),
    'yyyy-MM-dd'
  ),

  endDateString: format(
    endOfWeek(new Date(), { weekStartsOn: 1 }),
    'yyyy-MM-dd'
  )
})

export const mutations = {
  modifyLoading(state, countModifier) {
    state.loadingCount += countModifier
  },

  setStartDateString(state, string) {
    state.startDateString = string
  },

  setEndDateString(state, string) {
    state.endDateString = string
  },

  addSelectedCategory(state, data) {
    state.selectedCategories.push(data)
  },

  removeSelectedCategory(state, index) {
    state.selectedCategories.splice(index, 1)
  },

  setSelectedCategories(state, data) {
    state.selectedCategories = data
  },

  init(state) {
    state.startDateString = format(
      startOfWeek(new Date(), { weekStartsOn: 1 }),
      'yyyy-MM-dd'
    )
    state.endDateString = format(
      endOfWeek(new Date(), { weekStartsOn: 1 }),
      'yyyy-MM-dd'
    )
  }
}

export const getters = {
  currentUser(state) {
    return state.auth.user
  },

  isLoading(state) {
    return state.loadingCount > 0
  },

  startDate(state) {
    return parse(state.startDateString, 'yyyy-MM-dd', new Date())
  },

  endDate(state) {
    return parse(state.endDateString, 'yyyy-MM-dd', new Date())
  },

  dateRangeStrings(state) {
    return {
      start: state.startDateString,
      end: state.endDateString
    }
  },

  selectedCategories(state) {
    return JSON.stringify(state.selectedCategories)
  }
}
