<template>
  <b-form-invalid-feedback :state="invalidFeedbackState">
    <div v-if="isRequired">Bitte {{ label }} ausfüllen</div>
    <div v-if="isMinLength">
      Bitte mindestens {{ property.$params.minLength.min }} Zeichen eingeben
    </div>
    <div v-if="isMaxLength">
      Bitte höchstens {{ property.$params.maxLength.max }} Zeichen eingeben
    </div>
    <div v-if="isEmail">
      Bitte eine gültige E-Mail-Adresse eingeben
    </div>
  </b-form-invalid-feedback>
</template>

<script>
export default {
  name: 'FormValidationError',

  props: {
    property: {
      type: Object,
      required: true
    },

    label: {
      type: String,
      required: false,
      default: 'Feld'
    }
  },

  computed: {
    invalidFeedbackState() {
      return this.property.$error === false
    },

    hasValue() {
      if (typeof this.property.$model === 'string') {
        return this.property.$model && this.property.$model.trim().length > 0
      }
      if (typeof this.property.$model === 'number') {
        return this.property.$model && this.property.$model > 0
      }
      return this.property.$model !== null && this.property.$model !== undefined
    },

    isRequired() {
      return (
        (Object.prototype.hasOwnProperty.call(this.property, 'required') ||
          Object.prototype.hasOwnProperty.call(this.property, 'requiredIf')) &&
        !this.hasValue
      )
    },

    isEmail() {
      return (
        Object.prototype.hasOwnProperty.call(this.property, 'email') &&
        this.hasValue
      )
    },

    isMinLength() {
      return (
        Object.prototype.hasOwnProperty.call(this.property, 'minLength') &&
        this.property.$model.length < this.property.$params.minLength.min &&
        this.hasValue
      )
    },

    isMaxLength() {
      return (
        Object.prototype.hasOwnProperty.call(this.property, 'minLength') &&
        this.property.$model.length > this.property.$params.maxLength.max &&
        this.hasValue
      )
    }
  }
}
</script>
