const getPageRole = (route) => {
  let role = null

  route.matched.forEach((component) => {
    if (
      Object.prototype.hasOwnProperty.call(
        component.components.default.options,
        'auth'
      )
    ) {
      role = component.components.default.options.auth
    }
  })

  return role
}

export default function({ route, redirect, app, store }) {
  if (route.name === 'login') {
    if (!app.store.getters['auth/isLoggedIn']) {
      return
    }
    redirect('/')
  }

  if (!app.store.getters['auth/isLoggedIn']) {
    redirect('/login')
  }

  const pageRole = getPageRole(route)

  if (!pageRole) {
    return
  }

  if (!store.getters['auth/isGranted'](pageRole)) {
    // eslint-disable-next-line no-console
    console.error('You do not have permission to visit this page!')
    redirect('/')
  }
}
