<template>
  <div class="row">
    <div class="col-lg-6 col-xl-8 column-logo">
      <logo white />
    </div>
    <div class="col-lg-6 col-xl-4 column-content">
      <nuxt />
    </div>
  </div>
</template>

<script>
import '~/assets/app.scss'
import Logo from '~/components/Logo'
import '~/assets/webkit.css'

export default {
  name: 'LayoutLogin',

  components: { Logo },

  head() {
    return {
      htmlAttrs: {
        class: 'login'
      }
    }
  }
}
</script>
