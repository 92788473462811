import Swal from "sweetalert2";

export default function({ route }) {
  if (route.name !== 'login') {
    fetch(process.env.GRAPHQL_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: 'query AppVersion { appVersion }',
      })
    })
      .then(response => response.json())
      .then((data) => {
        const { appVersion: currentVersion } = data.data

        if (localStorage.getItem('app-version') === null) {
          localStorage.setItem('app-version', currentVersion)
        }

        let updateVersion = false

        if (currentVersion !== 'no-version') {
          if (process.env.VERSION !== undefined) {
            if (process.env.VERSION !== currentVersion) {
              updateVersion = true
            }
          } else if (localStorage.getItem('app-version') !== currentVersion) {
            updateVersion = true
          }
        }

        if (updateVersion) {
          Swal.fire({
            title: 'Neue Version',
            text: 'Um die App weiterhin wie gewohnt nutzen zu können, muss sie neu geladen werden.',
            showCancelButton: true,
            reverseButtons: true,
            cancelButtonText: 'Später',
            confirmButtonText: 'Neue Version laden',
            confirmButtonColor: '#227800',
          }).then((result) => {
            if (result.isConfirmed) {
              const form = document.createElement('form')
              form.id = 'reload-form';
              form.method = 'POST';
              document.body.appendChild(form)

              localStorage.setItem('app-version', currentVersion)

              document.getElementById('reload-form').submit()
            }
          })
        }
      })
  }
}
