<template>
  <b-modal
    ref="modal"
    title="Mitarbeiter wechseln"
    scrollable
    lazy
  >
    <template #modal-footer="{ cancel }">
      <div class="w-100">
        <div class="float-right">
          <b-button variant="link" @click="cancel()">
            Schließen
          </b-button>
        </div>
      </div>
    </template>

    <div class="list-group" style="overflow-y: auto; max-height: 450px; margin: -1rem;" :disabled="isLoading">
      <a v-for="user in users"
         :key="user.id"
         class="list-group-item list-group-item-action"
         href="#"
         @click.stop.prevent="onUserClick(user.abbreviation)">
        {{ user.abbreviation }} ({{ user.firstName }} {{ user.lastName }})
      </a>
    </div>
  </b-modal>
</template>

<script>
import { mapMutations } from "vuex";

import UsersQuery from '~/graphql/query/Users.gql'
import SwitchUserMutation from '~/graphql/mutation/SwitchUser.gql'

export default {
  name: 'EditDefaultWeeklyEventModal',

  data() {
    return {
      isLoading: false,
    }
  },

  methods: {
    ...mapMutations({
      reset: 'auth/reset'
    }),

    onUserClick(user) {
      this.$store.commit('modifyLoading', +1)

      this.$apollo.mutate({
        mutation: SwitchUserMutation,
        variables: {
          username: user
        }
      }).then((data) => {
        localStorage.setItem('user_switch', localStorage.getItem('auth_data'))
        this.reset()
        this.$store.commit('auth/login', data.data.switchUser)
        location.reload()
      }).finally(() => {
        this.$store.commit('modifyLoading', -1)
      })
    },

    undoSwitch() {
      this.$store.commit('modifyLoading', +1)
      this.reset()
      localStorage.setItem('auth_data', localStorage.getItem('user_switch'))
      localStorage.removeItem('user_switch')
      location.reload()
      // this.$store.commit('modifyLoading', -1)
    },

    show() {
      return this.$refs.modal.show()
    },
  },

  apollo: {
    users: {
      query: UsersQuery,
      variables: {
        onlyAssignable: false,
        hideCurrentUser: true,
      }
    }
  },
}
</script>
