<template>
  <div class="default">
    <loading />
    <sidebar />
    <main>
      <nuxt />
    </main>
  </div>
</template>

<script>
import '~/assets/app.scss'
import Sidebar from '~/components/Sidebar'
import '~/assets/webkit.css'
import Loading from '~/components/Loading'

export default {
  name: 'LayoutDefault',

  components: { Loading, Sidebar },

  mounted() {
    this.$sentry.setUser({
      email: this.$store.state.auth.user
    })
    this.$sentry.setTag('system', this.$config.system ?? (process.env.SENTRY_ENV ? process.env.SENTRY_ENV : (process.env.NUXT_ENV_SYSTEM ? process.env.NUXT_ENV_SYSTEM : (process.env.SYSTEM ? process.env.SYSTEM : 'unknown'))))
  }
}
</script>
